<template>
  <div class="drivingWarp">
    <div>
      <p class="tip">拍摄驾驶证副页查扣分</p>
      <div class="cardOcr">
        <img src="../../assets/img/driving/imgBg.png">
        <van-uploader class="camera"
                      :preview-image="false"
                      ref="drving"
                      style="position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%)"
                      v-model="fileList"
                      :after-read="afterRead" />

      </div>
      <div class="desc">点击上传驾驶证副页</div>

    </div>
    <van-form @submit="queryDriving"
              class="drivingForm">
      <div class="inputItem">
        <van-field label-width="1.6rem"
                   :maxlength="18"
                   v-model="jszh"
                   label="驾驶证号"
                   placeholder="请输入18位驾驶证编号"
                   input-align="right"
                   :rules="[{required:true,message:' ', validator:vaildJZZH}]" />
      </div>
      <div class="inputItem">
        <van-field label-width="1.6rem"
                   :maxlength="12"
                   v-model="dabh"
                   label="档案编号"
                   placeholder="请输入12位档案编号"
                   input-align="right"
                   :rules="[{required:true,message:' ',validator:vaildDABH}]" />

      </div>

      <div class=" flexbt">
        <van-field label-width="1.6rem"
                   class="inputItem"
                   :maxlength="4"
                   v-model="code"
                   label="验证码"
                   placeholder="请输入验证码"
                   input-align="right"
                   :rules="[{required:true,message:' ',validator:vailCode}]" />
        <div class="codeImgDiv"
             @click="getCodeImg"><img :src="codeImg"></div>

      </div>

      <div class="publicBtnDiv">
        <van-button class="publicBtn"
                    :class="{'publicBtnActive':isInput}"
                    round
                    block
                    native-type="submit">查 询</van-button>
      </div>
      <p class="errorMsg"
         v-if="jszError">请输入18位驾驶证编号</p>
      <p class="errorMsg"
         v-else-if="dabhError">请输入12位档案编号</p>
      <p class="errorMsg"
         v-else-if="msgError">请输入4位验证码</p>
    </van-form>

  </div>

</template>

<script>
import { getDrvingPoints } from "../../utils/tripartiteApi";
import common from "../../utils/common";
import { driverLicenOcr, getGraphCode, sendBankCardOcr } from "../../utils/api";
import {uwStatisticAction} from "../../utils/util";

export default {
  name: "driving",
  data () {
    return {
      formParam: {},
      dabh: '',
      jszh: '',
      validateKey: '',
      code: '',
      codeImg: '',
      isInput: false,
      jszError: false,
      dabhError: false,
      msgError: false,
      fileList: []

    }
  },
  // beforeRouteLeave (to, from, next) {
  //   if (to.name == "drvingResult") {
  //   } else {
  //     this.$store.commit('Remove_KEEP_ALIVE', 'drving')
  //   }
  //   next()
  // },
  methods: {
    vaildJZZH (val) {
      this.jszError = !common.IdCardVerify(val.replace(/\s+/g, ""))
      return !this.jszError
    },
    vaildDABH (val) {
      this.dabhError = val.replace(/\s+/g, "").length < 12
      return !this.dabhError
    },
    vailCode (val) {
      this.msgError = val.replace(/\s+/g, "").length < 4
      return !this.msgError
    },
    queryDriving () {
      this.formParam.dabh = this.dabh
      this.formParam.jszh = this.jszh
      this.formParam.code = this.code
      let that = this
      getDrvingPoints(this.formParam).then(res => {
        console.log("res", res)
        if (res.code === 200 && res.data.businessCode === 200) {
          this.$router.push({
            path: '/dr',
            query: {
              jszh: that.jszh.substring(0, 3) + "********" + that.jszh.substring(14),
              dabh: that.dabh.substring(0, 2) + "********" + that.dabh.substring(10),
              score: res.data.data.score
            }
          })
        }
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    if (to.name == "drvingResult") {
    } else {
      this.$store.dispatch('keepAliveState/removeKeepAlive', 'drving')
    }
    next()
  },
  methods: {
    vaildJZZH (val) {
      this.jszError = !common.IdCardVerify(val.replace(/\s+/g, ""))
      return !this.jszError
    },
    vaildDABH (val) {
      this.dabhError = val.replace(/\s+/g, "").length < 12
      return !this.dabhError
    },
    vailCode (val) {
      this.msgError = val.replace(/\s+/g, "").length < 4
      return !this.msgError
    },
    queryDriving () {
      this.formParam.dabh = this.dabh
      this.formParam.jszh = this.jszh
      this.formParam.code = this.code
      let that = this
      getDrvingPoints(this.formParam).then(res => {
        console.log("res", res)
        if (res.code === 200 && res.data.businessCode === 200) {
          this.$router.push({
            path: '/dr',
            query: {
              jszh: that.jszh.substring(0, 3) + "********" + that.jszh.substring(14),
              dabh: that.dabh.substring(0, 2) + "********" + that.dabh.substring(10),
              score: res.data.data.score

            }
          })
        } else {
          this.getCodeImg()
          this.code = ''
        }
      })
    },
    getCodeImg () {
      getGraphCode({}).then(res => {
        if (res.code === 200 && res.data.businessCode === 200) {
          this.codeImg = 'data:image/gif;base64,' + res.data.image
          this.formParam.validateKey = res.data.validateKey
        }

      })
    },
    chechInput () {
      if (this.vailCode(this.code) && this.vaildDABH(this.dabh) && this.vaildJZZH(this.jszh)) {
        this.isInput = true
      } else {
        this.isInput = false
      }
    },
    afterRead (file) {
      let self = this
      console.log(file)
      // 大于15M
      if (file.file.size > 15 * 1024 * 1024) {
        self.$toast('图片太大，请上传小于15M的图片')
        return
      }
      if (!common.imageVerify(file.file.type)) {
        this.fileList.splice(detail.index, 1)
        self.$toast('上传图片必须为png或者jpg格式')
        return
      }
      // 小于100K不进行压缩
      if (file.file.size < 100 * 1024) {
        this.saveFile(file.content)
        return
      }
      let reader = new FileReader()
      reader.readAsDataURL(file.file)
      let _this = this
      reader.onload = function (e) {
        let content = file.content //图片的src，base64格式
        let img = new Image()
        img.src = content
        img.onload = function () {
          common.compress(img, (dataURL) => {
            _this.saveFile(dataURL)
            // console.log(dataURL)
          })
        }
      }
    },
    saveFile (dataURL) {
      let params = {
        fileContent: dataURL
      }
      driverLicenOcr(params).then(res => {
        this.isOCR = true
        if (res.code === 200 && res.data.businessCode === 200) {
          this.jszh = res.data.driverNumber
          this.dabh = res.data.fileNumber
        }
      }).catch(() => {

      });
    },
  },
  watch: {
    dabh (newVal) {
      this.dabh = newVal.replace(/\s+/g, "")
      console.log("变化")
      this.chechInput()
    },
    code (newVal) {
      this.code = newVal.replace(/\s+/g, "")
      this.chechInput()
    },
    jszh (newVal) {
      this.jszh = newVal.replace(/\s+/g, "")
      this.chechInput()
    }

  },
  mounted () {
    console.log("tokne:", common.getItem('wtToken'))
    if (!common.getItem('wtToken')) {
      this.$router.push({
        path: '/main/login',
        query: {
          back001: '1'
        }
      })
    } else {
      this.getCodeImg()
    }
    uwStatisticAction('/driving','驾驶证查分')

  },

}
</script>

<style scoped lang="less">
.drivingWarp {
  padding: 20px 0 60px 0;
  background: #F7F8F9;
  min-height: 100%;
}
.inputItem {
  border-bottom: 0.01rem solid #F7F8F9;
  height: 1rem;
}
.desc{
  color: #3F7C53;
  text-align: center;
  font-size: 0.3rem;
}
.flexbt {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 10px;
}
.codeImgDiv {
  height: 40px;
  img {
    height: 40px;
    width: 120px;
  }
}
.errorMsg {
  padding-left: 15px;
  color: #ee0a24;
}
.cardOcr {
  position: relative;
  padding: 0.2rem 0.74rem;
  img {
    width: 100%;
  }
}
.drivingForm {
  margin-top: 38px;
}
.tip {
  font-size: 15px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  text-align: center;
  padding: 20px 0 8px 0;
}
</style>
<style>
.drivingWarp .van-cell{
  padding: 0.25rem 0.32rem;
}
.drivingWarp .van-field__label{
  font-size: 0.3rem;
  color: #333333;
}
.drivingWarp .van-field__value{
  font-size: 0.3rem;
}
.drivingWarp .van-field__error-message {
  margin-top: -2px;
}
.drivingWarp .van-field--error .van-field__control,
.van-field--error .van-field__control::placeholder {
  color: #999;
}
.camera .van-uploader__upload {
  width: 80px !important;
  height: 80px !important;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 50%;
}
.camera .van-uploader__upload i {
  background: url("../../assets/img/driving/xjimg.png");
  background-size: 100% 100%;
  width: 38px;
  height: 44px;
  display: inline-block;
}

.camera .van-uploader__upload i::before {
  display: none;
}
</style>
